<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
			<div class="row">
				<div :class="[showAddButton ? 'col-md-10' : 'col-md-12']">
					<a-input-search v-model="searchText" placeholder="Ingresa parámetros de búsqueda" enter-button @search="onSearch" :allowClear="true" />
				</div>
				<div class="col-md-2 text-right" v-if="showAddButton">
					<a-button class="btn btn-info btn-block" icon="plus" @click="onAddRecord">Añadir cliente</a-button>
				</div>
				<div class="col-md-12 pt10" v-if="clientsList.length > 0">
					<a-table :columns="columns" :dataSource="clientsList" :row-selection="rowSelection" rowKey="key" :pagination="false">
						<div slot="client_name" slot-scope="record">{{ record.client_name }} {{ record.client_surname }} {{ record.client_last_surname | replaceIfLogicalFalse('') }}</div>
						<div slot="phones" slot-scope="record">
							{{ record.primary_phone | replaceIfLogicalFalse('-No establecido-') }}
							<br />
							{{ record.secondary_phone | replaceIfLogicalFalse('-No establecido-') }}
						</div>
					</a-table>
				</div>
			</div>
			<div class="row pt10" v-if="showAddButtonAfterNullSearch && searchedClient">
				<div class="col-md-12">
					<hr class="hrDotted" />
				</div>
				<div class="col-md-4 offset-md-4 text-center">
					<a-button class="btn btn-info btn-block mt10" icon="plus" @click="onAddRecord">Añadir cliente</a-button>
				</div>
			</div>
		</a-spin>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'

export default {
	name: 'clientsQuickSearchComponent',
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		showAddButton: {
			type: Boolean,
			default: false,
		},
		showAddButtonAfterNullSearch: {
			type: Boolean,
			default: false,
		},
		searchType: {
			default: false,
		},
	},
	computed: {
		...mapGetters('clients', ['clientsList']),
		spinnerLoaderLabel() {
			return this.$store.state.clients.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.clients.spinnerLoader
		},
	},
	data() {
		return {
			selectedRecord: {},
			searchText: '',
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					key: 'id',
					align: 'center',
				},
				{
					title: 'Nombre del Cliente',
					scopedSlots: { customRender: 'client_name' },
				},
				{
					title: 'Teléfono / Celular',
					scopedSlots: { customRender: 'phones' },
				},
				{
					title: 'Email',
					dataIndex: 'email',
					key: 'email',
					align: 'center',
				},
			],
			rowSelection: {
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRecord = _.cloneDeep(selectedRows[0])
				},
				type: 'radio',
			},
			searchedClient: false,
		}
	},
	methods: {
		onSearch() {
			if (this.searchText == '') {
				this.$store.commit('clients/SET_STATE', {
					clientsList: [],
				})
				this.searchedClient = false
			} else {
				this.$store.dispatch('clients/SEARCH', { searchText: this.searchText, searchType: this.searchType })
				this.searchedClient = true
			}
		},
		onSelectedRecord() {
			if (utilities.objectValidate(this.selectedRecord, 'id', false)) {
				this.$emit('selectedRecord', this.selectedRecord)
			} else {
				Swal.fire({
					title: 'Catálogo de Clientes',
					text: 'Debes seleccionar un registro para continuar',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			}
		},
		onAddRecord() {
			this.$emit('addClient')
		},
	},
	watch: {
		visible() {
			this.$store.commit('clients/SET_STATE', {
				clientsList: [],
			})
			this.searchText = ''
			this.searchedClient = false
		},
	},
}
</script>